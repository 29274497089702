@font-face {
  font-family: "Apercu Light";
  src: url("https://gravitycoliving-cdn.s3.eu-west-1.amazonaws.com/apercu-light-webfont.ttf");
}
@font-face {
  font-family: "Edmondsans Bold";
  src: url("https://gravitycoliving-cdn.s3.eu-west-1.amazonaws.com/Edmondsans-Bold.otf");
}
@font-face {
  font-family: "Bota Regular";
  src: url("https://neybor-cdn.s3.eu-west-1.amazonaws.com/Bota-Regular.otf");
}



@import "../src/tailwind-preflight";

@tailwind components;
@tailwind utilities;

.enquiry-tool-rerum- {
  &gravitycoliving {
    font-family: "Apercu Light", sans-serif, system-ui!important;

    .react-calendar {
      max-width: 300px;
      font-family: "Apercu Light", sans-serif, system-ui !important;
    }

    .offer-line,
    .second-font {
      font-family: "Edmondsans Bold", sans-serif, system-ui !important;
    }

    .font-apercu {
      font-family: "Apercu Light", sans-serif, system-ui !important;
    }


  }
  &neybor {
    font-family: "Bota Regular", sans-serif, system-u!important;

    .react-calendar {
      max-width: 300px;
      font-family: "Bota Regular", sans-serif, system-ui !important;
    }

    .offer-line,
    .second-font {
      font-family: "Bota Regular", sans-serif, system-ui !important;
    }

    .font-apercu {
      font-family: "Bota Regular", sans-serif, system-ui !important;
    }

  }
}

#enquiry-tool-rerum {

  @import "react-calendar/dist/Calendar";
  @import "react-responsive-carousel/lib/styles/carousel";

  input[type="range"]::-webkit-slider-thumb {
    pointer-events: auto;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -webkit-appearance: none;
    @apply bg-gravity;
    cursor: pointer;
  }

  .carousel-root {
    .carousel-slider {
      height: 100%;
      display: flex;
      align-items: center;

      .control-arrow {
        top: unset;
        bottom: unset;
        margin: unset;
        padding: 20px;
      }
      .slider-wrapper {
        height: 100%;

        .slider {
          height: 100%;

          .slide {
            height: 100%;
          }
        }
      }
    }
  }
}
